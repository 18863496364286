<template>
    <v-container fluid class="application-table pa-0 pa-md-3">
		<p class="q-display-3">{{ title }}</p>
		<v-divider />

		<div class="data-table-alert">
			<v-chip color="#f79009" text-color="white" label class="data-table-alert__chip">
				<v-icon small>icon-alert-triangle-q</v-icon>
			</v-chip>
			<p class="ma-0"><strong class="mr-2">App Audit:</strong>Learn how to use the Submitted Applications dashboard to maintain secure oversight of you and your agents’ business.</p>
			<v-btn
				to="/page/app-audit-submitted-applications"
				class="action-btn ml-auto"
				color="#46c3b2"
			>
				Learn More
			</v-btn>
		</div>

		<div class="data-table-actions">
			<v-btn :id="filtersActivatorId" class="action-btn" :color="doShowFiltersMenu ? '#005851' : '#46c3b2'">
				<v-icon small left>icon-math-plus-q</v-icon> Add Filter
			</v-btn>
			<v-btn class="action-btn" :disabled="loading || !all_data.length" outlined color="#46c3b2" @click="showConfirmationDialog = true">
				<v-icon small left>icon-file-down-q</v-icon> Export Results
			</v-btn>
			<v-btn class="action-btn" outlined color="#46c3b2" @click="refresh">
				<v-icon small left>icon-refresh-q</v-icon> Refresh Table
			</v-btn>

			<v-text-field
				v-model="search"
				:disabled="isDemoMode"
				placeholder="Search name, phone, email, agent code, or policy number"
				prepend-inner-icon="icon-q-search"
				class="application-table-search ml-auto"
				dense single-line hide-details
				color="#46c3b2"
				outlined
				clearable
				@keyup="maybeSearch"
				@click:prepend-inner="refreshData"
				@click:clear="onSearchClear"
			/>
		</div>

		<QComprehensiveFilters
			ref="filters"
			v-model="filters"
			:filter-keys="filter_keys"
			:filters-data="filtersData"
			:activator-id="filtersActivatorId"
			class="mt-4 mb-4"
			@menu-update="doShowFiltersMenu = $event"
			@default-filters="isDefaultFilters = $event"
		/>

		<QExpandableDataTable
			v-model="selected"
			:items="all_data"
			:items-per-page.sync="rows"
			:server-items-length="total_items"
			:headers="the_headers"
			:mobile-breakpoint="2"
			:item-key="itemKey"
			:footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
			:loading="loading"
			:options.sync="options"
			class="pointer"
			hide-default-header
			@current-items="updateCurrentItems"
		>
			<template #header="{ props, on }">
				<tr class="data-table-header">
					<th
						v-for="header in props.headers"
						:key="header.value"
						:class="header.sortable && 'pointer'"
						@click.stop="() => {
							if (header.sortable) {
								on.sort(header.value)
							}
						}"
					>
						<div class="data-table-header-column">
							<span>{{ header.text }}</span>
							<v-tooltip v-if="header.tooltip" top>
								<template v-slot:activator="{ on, attrs }">
									<v-icon color="#1570ef" v-bind="attrs" v-on="on">icon-info-circle-q</v-icon>
								</template>
								<span>{{ header.tooltip }}</span>
							</v-tooltip>
							<v-icon
								v-if="header.sortable"
								:color="props.options.sortBy.includes(header.value) ? 'black' : '#d0d0d0'"
								:class="{ 'vertical-reflect': props.options.sortDesc.includes(true) > 0 }"
								small
							>icon-q-sort</v-icon>
						</div>
					</th>
				</tr>
			</template>
			<template v-slot:item.Agent="{ item }">
				<router-link
					v-if="item.AgentCode"
					class="nowrap data-table-content-link"
					:to="'/agents/' + item.AgentCode"
					@click.native.stop
				>
					<QHighlightedSearchString :item="item.Agent" :search="search" />
				</router-link>
				<span v-else class="nowrap">
					<QHighlightedSearchString :item="item.Agent" :search="search" />
				</span>
				<div class="data-table-content-details nowrap">
					<QHighlightedSearchString :item="item.AgentEmail" :search="search" />
				</div>
				<div class="data-table-content-details nowrap">
					<QHighlightedSearchString :item="item.AgentPhone" :search="search" />
				</div>
			</template>
			<template v-slot:item.Client="{ item }">
				<div class="nowrap">
					<QHighlightedSearchString :item="item.Client" :search="search" />
				</div>
				<div class="data-table-content-details nowrap">
					<QHighlightedSearchString :item="item.ClientEmail" :search="search" />
				</div>
				<div class="data-table-content-details nowrap">
					<QHighlightedSearchString :item="item.ClientPhone" :search="search" />
				</div>
			</template>
			<template v-slot:body.append>
				<tr class="data-table-total">
					<th>Total</th>
					<th colspan="5"></th>
					<th>{{ formattedTotalAPV }}</th>
					<th colspan="2"></th>
				</tr>
			</template>
			<template v-slot:expanded-item="{ item }">
				<v-row wrap class="data-table-expanded ma-0">
					<v-col v-for="table in detailedTables" :key="table.title" cols="6" xl="3">
						<p class="detailed-table-header">{{ table.title }}</p>
						<table class="detailed-table">
							<tbody>
								<tr v-for="column in table.columns" :key="column.key">
									<td>
										<strong>{{ column.title }}</strong>
									</td>
									<td>
										<template v-if="column.key === 'Agent'">
											<router-link
												v-if="item.AgentCode"
												class="nowrap data-table-content-link"
												:to="'/agents/' + item.AgentCode"
												@click.native.stop>{{ item.Agent }}</router-link>
											<span v-else class="nowrap">{{ item.Agent }}</span>
										</template>
										<template v-else-if="column.key === 'PolicyNumber'">
											<QHighlightedSearchString :item="item[column.key]" :search="search" />
										</template>
										<template v-else>{{ item[column.key] }}</template>
									</td>
								</tr>
							</tbody>
						</table>
					</v-col>
				</v-row>
			</template>
			<template #expand-icon>
				<v-icon>icon-q-down</v-icon>
			</template>

			<!-- Workaround as 'no-data' slot doesn't work -->
			<template v-slot:body.prepend>
				<tr v-if="!loading && !all_data.length" class="data-table-no-data-row">
					<th colspan="9">
						<div class="data-table-no-data">
							<Icon404 />
							<p class="data-table-no-data__title">No entries match your filters</p>
							<p class="data-table-no-data__subtitle">Trying removing some or all filters</p>
							<v-btn :disabled="isDefaultFilters" class="action-btn mt-4" color="#46c3b2" @click="resetAllFilters">
								Reset All Filters
							</v-btn>
						</div>
					</th>
				</tr>
			</template>
		</QExpandableDataTable>

		<v-dialog v-model="showConfirmationDialog" width="500px" max-width="90%" persistent>
            <v-card class="pa-6">
                <v-row>
                    <v-col cols="12">
                        <p>
                            You acknowledge and agree that (i) the information contained in this export is the Confidential Information of Symmetry Financial Group, LLC and its use and disclosure is subject to the terms of your Independent Contractor Agreement and (ii) any distribution of the information contained in this export to any other person will be considered a breach of your Independent Contractor Agreement.
                        </p>
                    </v-col>
                </v-row>
				<v-spacer></v-spacer>
				<v-row>
                    <v-col cols="12">
						<div class="d-flex justify-center">
							<v-btn class="mr-4" color="#46c3b2" @click="downloadCsvData">Confirm</v-btn>
							<v-btn depressed @click="showConfirmationDialog = false">Cancel</v-btn>
						</div>
					</v-col>
				</v-row>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment'
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI.js'
import Application from '@/store/Models/Application'
import QExpandableDataTable from '@/components/datatables/QExpandableDataTable.vue'
import Icon404 from '@/components/datatables/icons/Icon404.vue'
import QDatePeriodFilter from '@/components/utils/QDatePeriodFilter.vue'
import QComprehensiveFilters from '@/components/utils/QComprehensiveFilters.vue'
import QHighlightedSearchString from '@/components/utils/QHighlightedSearchString.vue'
import QPolicyTableStatFilter from '@/components/datatables/QPolicyTableStatFilter.vue'
import QDataTableMixin from './QDataTableMixin'

const the_header = [
	{
		text: 'Submitted',
		value: 'SubmittedDate',
		align: 'start',
		sortable: true,
		filterable: true,
	},
	{
		text: 'Agent',
		value: 'Agent',
		align: 'start',
		sortable: true,
		filterable: true,
		tooltip: 'Details about the agent who submitted the policy.',
	},
	{
		text: 'Client',
		value: 'Client',
		align: 'start',
		sortable: true,
		filterable: true,
		tooltip: 'Details about the client like name, email and phone number.',
	},
	{
		text: 'Client Address',
		value: 'ClientAddress',
		align: 'start',
		sortable: false,
		filterable: true
	},
	{
		text: 'Routing\xA0#',
		value: 'ClientRouting',
		align: 'start',
		sortable: false,
		filterable: true,
		tooltip: 'The routing number used for purchasing the policy.',
	},
	{
		text: 'Account\xA0#',
		value: 'ClientAccount',
		align: 'start',
		sortable: false,
		filterable: true,
		tooltip: 'The account number used for purchasing the policy.',
	},
	{
		text: 'APV',
		value: 'APV',
		align: 'start',
		sortable: true,
		filterable: true,
	},
	{
		text: 'Carrier',
		value: 'Carrier',
		align: 'start',
		sortable: true,
		filterable: true,
	},
]

const detailedTables = [
	{
		title: 'Policy',
		columns: [
			{
				key: 'SubmittedDate',
				title: 'Submitted Date',
			},
			{
				key: 'APV',
				title: 'APV',
			},
			{
				key: 'Carrier',
				title: 'Carrier',
			},
			{
				key: 'Product',
				title: 'Product',
			},
			{
				key: 'FaceAmount',
				title: 'Face Amount',
			},
			{
				key: 'PolicyNumber',
				title: 'Policy #',
			},
			{
				key: 'ProductType',
				title: 'Product Type',
			},
			{
				key: 'SplitPercent',
				title: 'Split',
			},
		],
	},
	{
		title: 'Agent',
		columns: [
			{
				key: 'Agent',
				title: 'Agent',
			},
			{
				key: 'AgentEmail',
				title: 'Agent Email',
			},
			{
				key: 'AgentPhone',
				title: 'Agent Phone #',
			},
		],
	},
	{
		title: 'Client',
		columns: [
			{
				key: 'Client',
				title: 'Client',
			},
			{
				key: 'ClientEmail',
				title: 'Client Email',
			},
			{
				key: 'ClientPhone',
				title: 'Client Phone #',
			},
			{
				key: 'ClientAddress',
				title: 'Client Address',
			},
			{
				key: 'ClientRouting',
				title: 'Client Routing #',
			},
			{
				key: 'ClientAccount',
				title: 'Client Account#',
			},
			{
				key: 'ClientDOB',
				title: 'Client Date of Birth',
			},
		],
	},
	{
		title: 'Lead Source',
		columns: [
			{
				key: 'Type',
				title: 'Type',
			},
			{
				key: 'Source',
				title: 'Source',
			},
		],
	},
]

const filtersConfig = {
	SubmitDate: { id: 'SubmitDate', type: 'datePeriod', icon: 'icon-calendar-date-selection-q', label: 'Submit Date', actionLabel: 'Save' },
	Type: { id: 'Type', type: 'checkboxes', icon: 'icon-tag-02', label: 'Lead Type' },
    ProductType: { id: 'ProductType', type: 'checkboxes', icon: 'icon-file-text-q', label: 'Product Type' },
    Carrier: { id: 'Carrier', type: 'checkboxes', icon: 'icon-office-building-q', label: 'Carrier' },
    statType: { id: 'statType', icon: 'icon-q-dataflow-1', label: 'Agency' },
}

const ALL = '-All-'

export default {
    name: 'QApplicationsAuditTable',
    components: {
		QExpandableDataTable,
		QPolicyTableStatFilter,
		QDatePeriodFilter,
		QHighlightedSearchString,
		Icon404,
		QComprehensiveFilters,
    },
    mixins: [QDataTableMixin],
    props: {
		agentCode: {
			type: String,
			default: '',
		},
	},
    data () {
        return {
            entity: 'application',
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
			allowGoTo: false,

            // use this to "preset" (default value) a filter
            filters: {
				SubmitDate: {
					startDate: null, // YYYY-MM-DD
					endDate: null, // YYYY-MM-DD
				},
				Type: ALL,
				ProductType: ALL,
				Carrier: ALL,
				statType: 'PersonalProduction', // TotalAgency | Baseshop | PersonalProduction
			},
			isDefaultFilters: false,

            applications: [],
			totalAPV: null,
			detailedTables,
            options: {
				sortBy: ['SubmittedDate'],
				sortDesc: [true],
				itemsPerPage: 25,
			},
			itemKey: 'IDX',

            showConfirmationDialog: false,

			filtersActivatorId: `add-filter-${Math.round(Math.random() * 10000)}`,
            doShowFiltersMenu: false,
        }
    },
	created () {
		Application.commit((state) => {
			state.loading = true
		})
	},
    computed: {
        filter_keys() {
            return ['SubmitDate', ...Application.state().filter_keys, 'statType']
        },
        all_data() {
            return this.applications.map((item, idx) => ({ ...item, IDX: idx }))
        },
        the_headers() {
			return the_header
        },
		computedAgentCode () {
			return this.agentCode ? this.agentCode : this.user.Agent.AgentCode
		},
        formattedTotalAPV () {
			if (!this.totalAPV) { return '' }
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.totalAPV)
        },
		filtersData () {
			return this.filter_keys.reduce((acc, key) => {
				acc[key] = {
					...filtersConfig[key],
					options: this.available_filters?.[key] || [],
				}
				return acc
			}, {})
		},
    },
    watch: {
		computedAgentCode() {
			this.options.page = 1
			this.refreshData()
		},
    },
    methods: {
        loadData: debounce(function(options) {
			const { SubmitDate, ...rest } = this.filters
            const filters = {
				...rest,
				...SubmitDate,
            }
            this.firstPull = false

            QuilityAPI.getSubmittedApplications(this.computedAgentCode, filters, options)
                .then((results) => {
                    if (typeof results.data !== "undefined") {
                        this.applications = results.data.map((item) => ({
							...item,
							originalAPV: item.APV,
							APV: this.formatCurrency(item.APV),
							FaceAmount: this.formatCurrency(item.FaceAmount),
						}))
						this.totalAPV = results.totalAPV
                    } else {
                        this.showError("Whoops! " + results.message);
                    }
                })
                .catch((err) => {
					if (err.message === 'Aborted') { return }
					this.showError('Whoops! There was a problem loading Submitted Policies.<br>', err)
					Application.commit((state) => {
                        state.loading = false
                    })
                })
        }, 200),
        refresh () {
            Application.commit((state) => {
				state.loading = true
                state.total = null
                state.availableFilters = null
            })
			this.applications = []
			this.totalAPV = null
            this.refreshData()
        },
        downloadCsvData() {
			const { SubmitDate, ...rest } = this.filters
            const params = {
                ...this.options,
                ...rest,
				...SubmitDate,
                sortBy: this.options.sortBy?.[0],
                sortDesc: (this.options.sortDesc?.length ? (this.options.sortDesc[0] ? 'true' : 'false') : null),
                search: this.search,
                itemsPerPage: 'csv',
            }
            const path = `/api/private/new_business/application_audit/${this.computedAgentCode}`
            this.dispatchJob({
                method: 'GET',
                path,
                params,
                JobName: 'Applications Export',
            })
			this.showConfirmationDialog = false
        },
        formatDate(value) {
            return moment(value).format("YYYY-MM-DD")
        },
        formatCurrency(value) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
        },
		resetAllFilters () {
			this.$refs.filters?.resetFilters?.()
		},
		onSearchClear () {
			this.search = null
			this.refreshData()
		},
    },
}
</script>

<style lang="scss">
$color-white: #ffffff;
$color-border-primary: #d0d5dd;
$color-border-secondary: #e4e4e4;
$color-bg-primary: #46c3b2;
$color-bg-active: #f8f8f8;
$color-bg-active: #f8f8f8;
$color-fg-brand-secondary: #8d8d8d;
$color-fg-tertiary: #8d8d8d;
$border-radius: 0.25rem;

.nowrap {
    white-space: nowrap;
}

.vertical-reflect {
	transform: scale(1, -1);
}

.action-btn {
	font-weight: 700;
	text-transform: none;
	box-shadow: none;
	border-radius: $border-radius;
	border-width: 2px;
	min-height: 40px;

	&:not(.v-btn--outlined) {
		&:not(.v-btn--disabled) {
			.v-btn__content {
				color: $color-white;
			}
		}
	}

	.v-icon {
		font-size: 1rem !important;
	}
}

.application-table {
	.data-table-actions {
		display: flex;
		gap: 1rem;
		margin: 2rem 0 0.5rem;
	}

	.application-table-search {
		margin-top: 0;
		max-width: 34rem;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		// border-radius: 8px;
		// border: 1px solid #E4E4E4;

		.v-input__slot {
			background-color: #F8F8F8;
		}
	}

	.data-table-alert {
		display: flex;
		align-items: center;
		gap: 1rem;
		margin: 1.5rem 0;
		padding: 1rem;
		border: 1px solid $color-border-primary;
		border-radius: $border-radius;
		background-color: $color-bg-active;

		&__chip {
			height: 2.25rem;
			width: 2.25rem;
			padding: 0;
			margin: 0;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.data-table-total {
		background-color: $color-bg-active;
	}

	tr.data-table-header {
		cursor: default;

		th.pointer {
			cursor: pointer;
		}
	}

	.data-table-header th {
		border-bottom: 1px solid $color-border-primary;
	}

	.data-table-header-column {
		display: flex;
		align-items: center;
		gap: 0.1rem;
	}

	.data-table-content-link {
		color: $color-bg-primary;
		text-decoration: underline !important;
	}

	.data-table-content-details {
		color: $color-fg-tertiary;
	}

	.data-table-expanded {
		padding: 2rem;
		background-color: $color-bg-active;
	}

	.detailed-table-header {
		font-size: 1.25rem;
		font-weight: bold;
	}

	.detailed-table {
		background-color: $color-white;
	}

	.v-data-table {
		th {
			font-size: 0.875rem;
		}

		.v-data-footer {
			border: none;

			.v-data-footer__icons-after .v-btn, .v-data-footer__icons-before .v-btn {
				background-color: $color-bg-primary;
				border-radius: 100%;

				&:not(.v-btn--disabled):hover {
					opacity: 0.7;
				}

				&.v-btn--disabled {
					opacity: 0.4;
				}
				
				.v-icon {
					color: $color-white !important;
				}
			}

			.v-data-footer__select .v-input {
				.v-input__slot {
					&:before, &:after {
						border: none;
					}
				}

				.v-input__control {
					border: 1px solid $color-border-primary;
					border-radius: $border-radius;
					background-color: $color-bg-active;
					padding-left: 0.25rem;
				}
			}
		}

		.v-data-table__expanded__content {
			box-shadow: none;

			.v-data-table {
				display: none;
			}
		}
	}

	table {
		border: 1px solid $color-border-secondary;
		border-radius: $border-radius;
	}

	tr.v-data-table__empty-wrapper {
		display: none;
	}
	.data-table-no-data-row {
		background-color: $color-bg-active;
		cursor: default;

		.data-table-no-data {
			width: 100%;
			padding: 1rem 0;
			display: flex;
			flex-direction: column;
			align-items: center;
	
			.data-table-no-data__title {
				font-size: 2rem;
				font-weight: 700;
				line-height: 1.25;
			}
	
			.data-table-no-data__subtitle {
				font-weight: 400;
				line-height: 1.25;
				color: #8d8d8d;
			}
		}
	}
}
</style>
